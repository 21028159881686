dmx.Component('scheduler', {

    initialData: {
        running: false,
        percent: 0
    },

    attributes: {
        delay: {
            type: Number,
            default: 60
        },

        unit: {
            type: String,
            default: 'seconds' // miliseconds, seconds, minutes, hours, days
        },

        norepeat: {
            type: Boolean,
            default: false
        },

        noload: {
            type: Boolean,
            default: false
        }
    },

    methods: {
        start: function() {
            this.start();
        },

        stop: function() {
            this.stop();
        }
    },

    events: {
        tick: Event
    },

    render: function(node) {
        if (!this.props.noload) {
            this.start();
        }
    },

    start: function() {
        this.set('running', true);
        this._startTime = (new Date()).getTime();
        this.tick();
    },

    stop: function() {
        this.set('running', false);
        this.set('percent', 0);
    },

    tick: function() {
        if (!this.data.running) {
            return;
        }

        var elapsed = new Date() - this._startTime;
        var total = this.delay();

        this.set('percent', Math.ceil(100 * elapsed / total));

        if (elapsed >= total) {
            this.set('percent', 100);

            this.dispatchEvent('tick');

            if (this.props.norepeat) {
                this.stop();
            } else {
                this.start();
            }

            return;
        }

        requestAnimationFrame(this.tick.bind(this));
    },

    delay: function() {
        switch (this.props.unit) {
            case 'miliseconds':
                return this.props.delay;
            case 'minutes':
                return this.props.delay * 60000;
            case 'hours':
                return this.props.delay * 3600000;
            case 'days':
                return this.props.delay * 86400000;
            default:
                // default seconds
                return this.props.delay * 1000;
        }
    }

});
